"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.function.name");
var _video = require("@/api/video");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      pictureAlbum: [],
      tagTypeList: [],
      pictureAlbumInex: 0,
      pictureTypeInex: 0,
      editCateData: {
        show: false,
        name: ''
      }
    };
  },
  computed: {
    typeData: function typeData() {
      return this.pictureAlbum[this.pictureAlbumInex].children ? this.pictureAlbum[this.pictureAlbumInex].children : [];
    },
    styleData: function styleData() {
      return this.pictureAlbum[this.pictureAlbumInex].children[this.pictureTypeInex] && this.pictureAlbum[this.pictureAlbumInex].children[this.pictureTypeInex].children ? this.pictureAlbum[this.pictureAlbumInex].children[this.pictureTypeInex].children : [];
    },
    typeName: function typeName() {
      console.log(this.pictureAlbum[this.pictureAlbumInex]);
      // return ''
      return this.pictureAlbum[this.pictureAlbumInex].name;
    },
    styleName: function styleName() {
      return this.pictureAlbum[this.pictureAlbumInex].children[this.pictureTypeInex] ? this.pictureAlbum[this.pictureAlbumInex].children[this.pictureTypeInex].name : '';
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.getList();
    (0, _video.getTagAndTypesListApi)({
      type: 2,
      is_group: 1
    }).then(function (res) {
      var row = res.data.rows.find(function (item) {
        return item.id === 6;
      });
      _this.tagTypeList = row ? row.tags : [];
    });
  },
  methods: {
    getList: function getList() {
      var _this2 = this;
      (0, _video.getCategoryListApi)().then(function (res) {
        console.log(res);
        _this2.pictureAlbum = res.data;
      });
    },
    onClick: function onClick(filed, index) {
      this[filed] = index;
    },
    onAdd: function onAdd() {
      console.log(this.pictureAlbum[this.pictureAlbumInex]);
      var row = this.pictureAlbum[this.pictureAlbumInex];
      this.editCateData = {
        show: true,
        tagId: row.tagId,
        parentId: row.id
      };
    },
    onAddOne: function onAddOne() {
      console.log(this.pictureAlbum[this.pictureAlbumInex].children[this.pictureTypeInex]);
      var row = this.pictureAlbum[this.pictureAlbumInex].children[this.pictureTypeInex];
      this.editCateData = {
        show: true,
        tagId: row.tagId,
        parentId: row.id
      };
    },
    onUpd: function onUpd(item) {
      this.editCateData = (0, _objectSpread2.default)({
        show: true
      }, item);
    },
    saveAction: function saveAction() {
      var _this3 = this;
      (0, _video.setCategoryEditApi)(this.editCateData).then(function (res) {
        _this3.editCateData.show = false;
        _this3.getList();
      });
    },
    modalPicTap: function modalPicTap(filed) {
      var that = this;
      this.$modalUpload(function (img) {
        // that.ruleForm[filed] = img[0];
        that.$set(that.editCateData, filed, img[0]);
      }, 1, 1);
    }
  }
};