var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "40px 0" } },
    [
      _c(
        "div",
        { staticClass: "flex", staticStyle: { "margin-bottom": "40px" } },
        _vm._l(_vm.pictureAlbum, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticStyle: { "margin-right": "20px" },
              on: {
                click: function ($event) {
                  return _vm.onClick("pictureAlbumInex", index)
                },
              },
            },
            [
              _c("div", { staticClass: "upLoadPicBox" }, [
                item.image
                  ? _c("div", { staticClass: "pictrue" }, [
                      _c("img", { attrs: { src: item.image + "!120a" } }),
                    ])
                  : _c("div", { staticClass: "upLoad" }, [
                      _c("i", { staticClass: "el-icon-camera cameraIconfont" }),
                    ]),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(item.name))]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onUpd(item)
                    },
                  },
                },
                [_vm._v("编辑")]
              ),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm._v("类型(" + _vm._s(_vm.typeName) + ")"),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "20px", "margin-bottom": "20px" },
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.onAdd },
            },
            [_vm._v("新增")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex", staticStyle: { "margin-bottom": "40px" } },
        _vm._l(_vm.typeData, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticStyle: { "margin-right": "20px" },
              on: {
                click: function ($event) {
                  return _vm.onClick("pictureTypeInex", index)
                },
              },
            },
            [
              _c("div", { staticClass: "upLoadPicBox" }, [
                item.image
                  ? _c("div", { staticClass: "pictrue" }, [
                      _c("img", { attrs: { src: item.image + "!120a" } }),
                    ])
                  : _c("div", { staticClass: "upLoad" }, [
                      _c("i", { staticClass: "el-icon-camera cameraIconfont" }),
                    ]),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(item.name))]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onUpd(item)
                    },
                  },
                },
                [_vm._v("编辑")]
              ),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm._v("风格(" + _vm._s(_vm.styleName) + ")"),
          _vm.pictureAlbum[_vm.pictureAlbumInex].children[_vm.pictureTypeInex]
            ? _c(
                "el-button",
                {
                  staticStyle: {
                    "margin-left": "20px",
                    "margin-bottom": "20px",
                  },
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.onAddOne },
                },
                [_vm._v("新增")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex" },
        _vm._l(_vm.styleData, function (item, index) {
          return _c(
            "div",
            { key: index, staticStyle: { "margin-right": "20px" } },
            [
              _c("div", { staticClass: "upLoadPicBox" }, [
                item.image
                  ? _c("div", { staticClass: "pictrue" }, [
                      _c("img", { attrs: { src: item.image + "!120a" } }),
                    ])
                  : _c("div", { staticClass: "upLoad" }, [
                      _c("i", { staticClass: "el-icon-camera cameraIconfont" }),
                    ]),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(item.name))]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onUpd(item)
                    },
                  },
                },
                [_vm._v("编辑")]
              ),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editCateData.show,
            width: "500px",
            "close-on-click-modal": false,
            title: _vm.editCateData.id ? "修改类型" : "添加类型",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editCateData, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: { model: _vm.editCateData, "label-width": "0px" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入标签名称" },
                    model: {
                      value: _vm.editCateData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.editCateData, "name", $$v)
                      },
                      expression: "editCateData.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "选择海报类型" },
                      model: {
                        value: _vm.editCateData.tagId,
                        callback: function ($$v) {
                          _vm.$set(_vm.editCateData, "tagId", $$v)
                        },
                        expression: "editCateData.tagId",
                      },
                    },
                    _vm._l(_vm.tagTypeList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { value: item.id, label: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: " " } }, [
                _c(
                  "div",
                  {
                    staticClass: "upLoadPicBox",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap("image")
                      },
                    },
                  },
                  [
                    _vm.editCateData.image
                      ? _c("div", { staticClass: "pictrue" }, [
                          _c("img", {
                            attrs: { src: _vm.editCateData.image + "!120a" },
                          }),
                        ])
                      : _c("div", { staticClass: "upLoad" }, [
                          _c("i", {
                            staticClass: "el-icon-camera cameraIconfont",
                          }),
                        ]),
                  ]
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveAction } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }