"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
var _video = require("@/api/video");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserGroup',
  data: function data() {
    return {
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        type: 1
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tagTypeList: [],
      editCateData: {
        show: false,
        name: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入分类名称',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _video.getTagAndTypesListApi)({
      type: 2,
      is_group: 1
    }).then(function (res) {
      var row = res.data.rows.find(function (item) {
        return item.id === 5;
      });
      console.log(row);
      _this.tagTypeList = row ? row.tags : [];
    });
    this.getList();
  },
  methods: {
    modalPicTap: function modalPicTap(filed) {
      var that = this;
      this.$modalUpload(function (img) {
        // that.ruleForm[filed] = img[0];
        that.$set(that.editCateData, filed, img[0]);
      }, 1, 1);
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _video.getCalendarListApi)(this.tableFrom).then(function (res) {
        console.log(res);
        _this2.tableData.data = res.data.rows;
        _this2.tableData.total = res.data.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    // 添加
    onAdd: function onAdd() {
      this.editCateData = {
        show: true,
        name: '',
        fixedDate: '',
        tagId: '',
        imgurl: ''
      };
    },
    // 编辑
    onEdit: function onEdit(m) {
      this.editCateData = {
        id: m.id,
        show: true,
        name: m.name,
        fixedDate: m.fixedDate,
        tagId: m.tagId,
        imgurl: m.imgurl
      };
    },
    saveAction: function saveAction() {
      var _this3 = this;
      if (!this.editCateData.name) {
        this.$message.error('请输入日历名称');
        return false;
      }
      this.$confirm("\u786E\u8BA4".concat(this.editCateData.id ? '修改' : '添加', "\u8BE5\u65E5\u5386?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var action = _video.setCalendarAddApi;
        if (_this3.editCateData.id) {
          action = _video.setCalendarEditApi;
        }
        action(_this3.editCateData).then(function () {
          _this3.$message.success('操作成功');
          _this3.getList();
          _this3.editCateData.show = false;
        }).catch(function (_ref) {
          var message = _ref.message;
        });
      }).catch(function () {});
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this4 = this;
      this.$modalSure().then(function () {
        (0, _video.getCalendarDeleteApi)(id).then(function (_ref2) {
          var message = _ref2.message;
          _this4.$message.success('操作成功');
          _this4.tableData.data.splice(idx, 1);
        }).catch(function (_ref3) {
          var message = _ref3.message;
        });
      });
    }
  }
};