"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var cmsRouter = {
  path: "".concat(_settings.roterPre, "/video"),
  name: 'video',
  meta: {
    icon: 'dashboard',
    title: '视频'
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: 'vIndex',
    name: 'vIndex',
    meta: {
      title: '视频组件管理',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/video/videoIndex'));
      });
    },
    redirect: "".concat(_settings.roterPre, "/video/vIndex/template"),
    children: [{
      path: 'template',
      name: 'templateList',
      meta: {
        title: '视频模版管理',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/video/template'));
        });
      }
    }, {
      path: "AETemplate",
      name: 'AETemplate',
      meta: {
        title: 'AE模版',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/video/AETemplate'));
        });
      }
    }, {
      path: 'assembly',
      name: 'assembly',
      meta: {
        title: '视频组件管理',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/video/assembly'));
        });
      }
    }, {
      path: 'log',
      name: 'log',
      meta: {
        title: '生成记录',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/video/log'));
        });
      }
    }]
  }, {
    path: "posterList",
    name: 'posterList',
    meta: {
      title: '海报',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/video/posterIndex'));
      });
    },
    redirect: "".concat(_settings.roterPre, "/video/posterList/template"),
    children: [{
      path: 'template',
      name: 'posterTemplate',
      meta: {
        title: '海报模版',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/video/posterTemplate'));
        });
      }
    }, {
      path: 'component',
      name: 'posterComponent',
      meta: {
        title: '海报组件',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/video/posterComponent'));
        });
      }
    }, {
      path: 'log',
      name: 'posterLog',
      meta: {
        title: '海报生成记录',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/video/posterLog'));
        });
      }
    }, {
      path: 'feedback',
      name: 'posterFeedback',
      meta: {
        title: '结果反馈',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/video/posterFeedback'));
        });
      }
    }, {
      path: 'tags',
      name: 'posterTags',
      meta: {
        title: '海报标签',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/video/posterTags'));
        });
      }
    }, {
      path: 'calendar',
      name: 'posterCalendar',
      meta: {
        title: '海报日历',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/video/posterCalendar'));
        });
      }
    }, {
      path: 'pictureType',
      name: 'pictureType',
      meta: {
        title: '海报日历',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/video/pictureType'));
        });
      }
    }]
  }, {
    path: 'templateDetail',
    name: 'templateDetail',
    meta: {
      title: '视频模版详情',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/video/templateDetail'));
      });
    }
  }, {
    path: 'fontList',
    name: 'fontList',
    meta: {
      title: '字体管理',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/video/fontList'));
      });
    }
  }, {
    path: 'artistList',
    name: 'artistList',
    meta: {
      title: '艺术家管理',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/video/artistList'));
      });
    }
  }, {
    path: "AETemplateDetails",
    name: 'AETemplateDetails',
    meta: {
      title: 'AE模版详情',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/video/AETemplateDetails'));
      });
    }
  }, {
    path: "AEBind",
    name: 'AEBind',
    meta: {
      title: 'AE模版绑定',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/video/AEBind'));
      });
    }
  }]
};
var _default = exports.default = cmsRouter;