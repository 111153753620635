var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "flex mt10" }, [
        _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
          _vm._v("业务场景："),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml5" },
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "medium" },
                on: {
                  change: function ($event) {
                    return _vm.getList(1)
                  },
                },
                model: {
                  value: _vm.tableFrom.data_type,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "data_type", $$v)
                  },
                  expression: "tableFrom.data_type",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "" } }, [
                  _vm._v("全部"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.typeList, function (item, index) {
                  return _c(
                    "el-radio-button",
                    { key: index, attrs: { label: item.type } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                _vm._v("=\n      "),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex mt10" }, [
        _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
          _vm._v("画面尺寸："),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml5 flex-one" },
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "medium" },
                on: {
                  change: function ($event) {
                    return _vm.getList(1)
                  },
                },
                model: {
                  value: _vm.tableFrom.size,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "size", $$v)
                  },
                  expression: "tableFrom.size",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "" } }, [
                  _vm._v("全部"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.sizeList, function (item, index) {
                  return _c(
                    "el-radio-button",
                    { key: index, attrs: { label: item.actual } },
                    [_vm._v(_vm._s(item.actual) + " " + _vm._s(item.name))]
                  )
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex mt10" }, [
        _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
          _vm._v("模版标签："),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml5 flex-one" },
          _vm._l(_vm.tagList, function (item, index) {
            return _c("div", { key: index }, [
              _c(
                "div",
                { staticStyle: { height: "35px", "line-height": "35px" } },
                [_vm._v(_vm._s(item.name))]
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-checkbox-group",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.tableFrom.tag_ids,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "tag_ids", $$v)
                        },
                        expression: "tableFrom.tag_ids",
                      },
                    },
                    _vm._l(item.tags, function (city) {
                      return _c(
                        "el-checkbox-button",
                        { key: city.id, attrs: { label: city.id } },
                        [_vm._v(_vm._s(city.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex mt10" }, [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
            _vm._v("设计师："),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml5", staticStyle: { flex: "1" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "medium" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.create_user_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "create_user_id", $$v)
                    },
                    expression: "tableFrom.create_user_id",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "" } }, [
                    _vm._v("全部"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.adminarr, function (item, index) {
                    return _c(
                      "el-radio-button",
                      { key: index, attrs: { label: item.userId } },
                      [_vm._v(_vm._s(item.nickName))]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex ml20" }, [
          _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
            _vm._v("模版状态："),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml5", staticStyle: { flex: "1" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "medium" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "status", $$v)
                    },
                    expression: "tableFrom.status",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "" } }, [
                    _vm._v("全部"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: 1 } }, [
                    _vm._v("上架"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: 0 } }, [
                    _vm._v("下架"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex ml20" }, [
          _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
            _vm._v("专属模版："),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml5", staticStyle: { flex: "1" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "medium" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.is_custom,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "is_custom", $$v)
                    },
                    expression: "tableFrom.is_custom",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "" } }, [
                    _vm._v("全部"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("是"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "2" } }, [
                    _vm._v("否"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex align-items-c mt20" },
        [
          _c(
            "div",
            [
              _c(
                "el-input",
                {
                  staticClass: "w300",
                  attrs: { placeholder: "模版名称", clearable: "" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.keyword,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "keyword", $$v)
                    },
                    expression: "tableFrom.keyword",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml20" },
            [
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.onChangeAllCheck },
                  model: {
                    value: _vm.checkAll,
                    callback: function ($$v) {
                      _vm.checkAll = $$v
                    },
                    expression: "checkAll",
                  },
                },
                [_vm._v(_vm._s(_vm.checkAll ? "取消" : "全选"))]
              ),
              _vm._v(" "),
              _c("span", { staticStyle: { margin: "0 20px" } }, [
                _vm._v("已选" + _vm._s(_vm.selectKey.length)),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "mr10",
                  attrs: { type: "primary", label: "default", size: "small" },
                  on: { click: _vm.onAllOpen },
                },
                [_vm._v("批量加标签")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "ml20",
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.onLogin },
            },
            [_vm._v("登录海报")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { ref: "wapperRef", staticClass: "mt20" }, [
        _vm.gridList.length > 0
          ? _c(
              "div",
              {
                staticClass: "wapperBox",
                style: { height: _vm.wapperHeigth + "px" },
              },
              _vm._l(_vm.gridList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "wapper-item",
                    style: {
                      width: _vm.realWidth + "px",
                      height: "" + item.boxheight,
                      top: "" + item.top,
                      left: "" + item.left,
                    },
                  },
                  [
                    _c(
                      "el-tag",
                      {
                        staticClass: "ggBut",
                        attrs: { type: item.status == 0 ? "danger" : "" },
                        on: {
                          click: function ($event) {
                            return _vm.onSwitchStatus(item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(item.status == 0 ? "下架" : "上架") + " "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-tag",
                      {
                        staticClass: "tagBut",
                        on: {
                          click: function ($event) {
                            return _vm.onUpdTagOpen(item)
                          },
                        },
                      },
                      [_vm._v(" 编辑标签 ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tjBut" },
                      [
                        _c("el-checkbox", {
                          on: {
                            change: function (e) {
                              return _vm.onChangeCheck(e, item)
                            },
                          },
                          model: {
                            value: item.check,
                            callback: function ($$v) {
                              _vm.$set(item, "check", $$v)
                            },
                            expression: "item.check",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("img", {
                      staticStyle: { width: "100%" },
                      attrs: { src: item.preview_image, alt: "" },
                      on: {
                        click: function ($event) {
                          return _vm.onOpenPoster(item)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "nameBox" }, [
                      _vm._v("模版名称：" + _vm._s(item.name)),
                    ]),
                  ],
                  1
                )
              }),
              0
            )
          : _c("div", { staticClass: "noDataBox" }, [_vm._v("暂无数据")]),
        _vm._v(" "),
        _c("div", { attrs: { id: "footer" } }),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.formState.show,
            title: "新增标签",
            "append-to-body": "",
            width: "600px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.formState, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm ",
              attrs: {
                model: _vm.formState,
                "label-width": "110px",
                "label-position": "top",
              },
            },
            [
              _vm._l(_vm.tagList, function (item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: item.name } },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            model: {
                              value: _vm.formState.tag_ids,
                              callback: function ($$v) {
                                _vm.$set(_vm.formState, "tag_ids", $$v)
                              },
                              expression: "formState.tag_ids",
                            },
                          },
                          _vm._l(item.tags, function (city) {
                            return _c(
                              "el-checkbox-button",
                              { key: city.id, attrs: { label: city.id } },
                              [_vm._v(_vm._s(city.name))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }