"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AECreateApi = AECreateApi;
exports.AEEditApi = AEEditApi;
exports.AETInfoApi = AETInfoApi;
exports.AETListApi = AETListApi;
exports.AETemTypeUpdApi = AETemTypeUpdApi;
exports.backgroundMusicListAPI = backgroundMusicListAPI;
exports.backgroundMusictreeListAPI = backgroundMusictreeListAPI;
exports.componentTypesList = componentTypesList;
exports.delFontApi = delFontApi;
exports.filedSaveApi = filedSaveApi;
exports.fontListAPI = fontListAPI;
exports.fontListApi = fontListApi;
exports.fontUpdateApi = fontUpdateApi;
exports.getCalendarDeleteApi = getCalendarDeleteApi;
exports.getCalendarListApi = getCalendarListApi;
exports.getCategoryListApi = getCategoryListApi;
exports.getPosterTemplateListApi = getPosterTemplateListApi;
exports.getRecommendApi = getRecommendApi;
exports.getRecordsListApi = getRecordsListApi;
exports.getStatusUpdateApi = getStatusUpdateApi;
exports.getTagAndTypesListApi = getTagAndTypesListApi;
exports.getTagDeleteApi = getTagDeleteApi;
exports.getTagSaveUpdApi = getTagSaveUpdApi;
exports.getTagTypeApi = getTagTypeApi;
exports.getVideoComponentCopyAPI = getVideoComponentCopyAPI;
exports.getVideoComponentDetailAPI = getVideoComponentDetailAPI;
exports.getVideoComponentFontListAPI = getVideoComponentFontListAPI;
exports.getVideoComponentListAPI = getVideoComponentListAPI;
exports.getVideoPurposeListAPI = getVideoPurposeListAPI;
exports.getVideoTypeListAPI = getVideoTypeListAPI;
exports.gettemplateDetailApi = gettemplateDetailApi;
exports.iceInfoApi = iceInfoApi;
exports.iceSaveApi = iceSaveApi;
exports.registerMediaInfoAPI = registerMediaInfoAPI;
exports.saasAlbumDetailAPI = saasAlbumDetailAPI;
exports.saasAlbumListAPI = saasAlbumListAPI;
exports.saasAuctionDetailAPI = saasAuctionDetailAPI;
exports.saasMerchantListAPI = saasMerchantListAPI;
exports.saasProductListAPI = saasProductListAPI;
exports.saasVenueDetailAPI = saasVenueDetailAPI;
exports.saasVenueListAPI = saasVenueListAPI;
exports.saveFontApi = saveFontApi;
exports.saveOrUpdateVideoComponentAPI = saveOrUpdateVideoComponentAPI;
exports.scrollPictureTaskApi = scrollPictureTaskApi;
exports.scrollPictureTaskUpdateApi = scrollPictureTaskUpdateApi;
exports.setCalendarAddApi = setCalendarAddApi;
exports.setCalendarEditApi = setCalendarEditApi;
exports.setCategoryEditApi = setCategoryEditApi;
exports.setTemplateSsaveApi = setTemplateSsaveApi;
exports.submitGenerationAPI = submitGenerationAPI;
exports.temFieldConfigApi = temFieldConfigApi;
exports.temFieldListApi = temFieldListApi;
exports.templateCopyAPI = templateCopyAPI;
exports.templateDelAPI = templateDelAPI;
exports.templateDetailAPI = templateDetailAPI;
exports.templateEditStatusAPI = templateEditStatusAPI;
exports.templateListAPI = templateListAPI;
exports.templateSaveAPI = templateSaveAPI;
exports.templateUpdateAPI = templateUpdateAPI;
exports.testRenderApi = testRenderApi;
exports.updateFileApi = updateFileApi;
exports.videoComponentDelAPI = videoComponentDelAPI;
exports.videoComponentEditAPI = videoComponentEditAPI;
exports.voiceListAPI = voiceListAPI;
exports.workflowListApi = workflowListApi;
var _request = _interopRequireDefault(require("./request"));
// 获取用途列表
function getVideoPurposeListAPI(data) {
  return _request.default.get("/ice/video/template/purposeList", data);
}

// 获取类型列表
function getVideoTypeListAPI(data) {
  return _request.default.get("/ice/video/template/typeList", data);
}

// 获取组件列表
function getVideoComponentListAPI(data) {
  return _request.default.get("/ice/video/component/list", data);
}

// 获取组件字体列表
function getVideoComponentFontListAPI(data) {
  return _request.default.get("/ice/video/font/list", data);
}

// 复制组件
function getVideoComponentCopyAPI(id) {
  return _request.default.post("/ice/video/component/copy/".concat(id));
}

// 获取组件内容
function getVideoComponentDetailAPI(id) {
  return _request.default.get("/ice/video/component/".concat(id), {});
}

// 获取组件内容
function saveOrUpdateVideoComponentAPI(data) {
  return _request.default.post("/ice/video/component/saveOrUpdate", data);
}

// 删除组件
function videoComponentDelAPI(id) {
  return _request.default.delete("/ice/video/component/".concat(id));
}
// 创建 修改组件
function videoComponentEditAPI(data) {
  return _request.default.post("/ice/video/component/saveOrUpdate", data);
}
// 注册媒资
function registerMediaInfoAPI(data) {
  return _request.default.post("/ice/video/template/registerMediaInfo", data);
}

// 搜索商户
function saasMerchantListAPI(data) {
  return _request.default.get("/ice/video/template/saasMerchantList", data);
}
// 搜索拍卖会
function saasVenueListAPI(data) {
  return _request.default.get("/ice/video/template/saasVenueList", data);
}
// 拍卖会详情
function saasVenueDetailAPI(id, data) {
  return _request.default.get("/ice/video/template/venue/".concat(id), data);
}
// 专场详情
function saasAlbumDetailAPI(id, data) {
  return _request.default.get("/ice/video/template/album/".concat(id), data);
}
// 拍品详情
function saasAuctionDetailAPI(id, data) {
  return _request.default.get("/ice/video/template/product/".concat(id), data);
}
// 搜索专场
function saasAlbumListAPI(data) {
  return _request.default.get("/ice/video/template/saasAlbumList", data);
}
// 搜索拍品
function saasProductListAPI(data) {
  return _request.default.get("/ice/video/template/saasProductList", data);
}
// 背景音乐
function backgroundMusicListAPI(data) {
  return _request.default.get("/ice/video/background/music/list", data);
}
// 背景音乐 树结构
function backgroundMusictreeListAPI(data) {
  return _request.default.get("/ice/video/background/music/treeList", data);
}
// 字体列表
function fontListAPI(data) {
  return _request.default.get("/ice/video/font/list", data);
}
// 人声列表
function voiceListAPI(data) {
  return _request.default.get("/ice/video/voice/list", data);
}

// 模版列表
function templateListAPI(data) {
  return _request.default.get("/ice/video/template/list", data);
}

// 模版创建
function templateSaveAPI(data) {
  return _request.default.post("/ice/video/template/save", data);
}
// 模版修改
function templateUpdateAPI(data) {
  return _request.default.put("/ice/video/template/update", data);
}
// 模版删除
function templateDelAPI(id) {
  return _request.default.delete("/ice/video/template/".concat(id));
}
// 模版复制
function templateCopyAPI(id) {
  return _request.default.post("/ice/video/template/copy/".concat(id));
}

// 模版详情
function templateDetailAPI(id) {
  return _request.default.get("/ice/video/template/".concat(id));
}

// 模版详情
function submitGenerationAPI(data) {
  return _request.default.post("/ice/video/submitGenerationV2", data);
}
// 模版修改状态
function templateEditStatusAPI(data) {
  return _request.default.put("/ice/video/template/unmount", data);
}

// 保存字体
function saveFontApi(data) {
  return _request.default.post("/open3d/ice/video/font/save", data);
}

// 保存字体
function fontUpdateApi(data) {
  return _request.default.put("/open3d/ice/video/font/update", data);
}

// 删除字体
function delFontApi(id) {
  return _request.default.delete("/open3d/ice/video/font/".concat(id));
}

// 字体列表
function fontListApi(data) {
  return _request.default.get("/open3d/ice/video/font/pageList", data);
}

// 类型列表
function componentTypesList(data) {
  return _request.default.get("/open3d/ice/video/componentTypes", data);
}

// 获取滚动长图任务
function scrollPictureTaskApi(id) {
  return _request.default.get("/open3d/ice/video/scrollPictureTask/".concat(id));
}
// 更新滚动长图任务
function scrollPictureTaskUpdateApi(data) {
  return _request.default.put("/open3d/ice/video/scrollPictureTask", data);
}

// 
function AETListApi(data) {
  return _request.default.get("/open3d/ice/sys/ve/template/list", data);
}

// 保存AE模板
function AECreateApi(data) {
  return _request.default.post("/open3d/ice/sys/ve/create/template", data);
}

// 
function AETInfoApi(id) {
  return _request.default.get("/open3d/ice/sys/ve/get/template/".concat(id));
}

// 保存AE模板
function AEEditApi(data) {
  return _request.default.post("/open3d/ice/sys/ve/edit/template", data);
}

// 
function AETemTypeUpdApi(data) {
  return _request.default.post("/open3d/ice/sys/ve/operation/template", data);
}

/**
 * @description 
 */
function iceInfoApi(id, type) {
  return _request.default.get("/open3d/ice/app/ve/get/template/info/".concat(id, "?type=").concat(type));
}

/**
 * @description 
 */
function iceSaveApi(data) {
  return _request.default.post('/open3d/ice/app/ve/save/template/info', data);
}

/**
 * @description 
 */
function temFieldConfigApi(id) {
  return _request.default.get("/open3d/ice/sys/ve/template/field/config/".concat(id));
}

/**
 * @description 
 */
function temFieldListApi() {
  return _request.default.get("/open3d/ice/sys/ve/template/field/list");
}

/**
 * @description 
 */
function workflowListApi() {
  return _request.default.get("/open3d/ice/sys/ve/template/workflow/list");
}

/**
 * @description 
 */
function filedSaveApi(data) {
  return _request.default.post('/open3d/ice/sys/ve/template/field/config/set', data);
}

/**
 * @description 
 */
function testRenderApi(data) {
  return _request.default.post('/open3d/ice/sys/ve/template/test/render', data);
}

/**
 * @description 
 */
function updateFileApi(data) {
  return _request.default.post('/open3d/ice/sys/ve/template/update/file', data);
}

/**
 * @description 
 */
function getRecordsListApi(data) {
  return _request.default.get("/open3d/poster/sys/poster/records/list", data);
}

/**
 * @description 
 */
function getRecommendApi(data) {
  return _request.default.post("/open3d/poster/sys/recommend", data);
}

/**
 * @description 
 */
function getTagAndTypesListApi(data) {
  return _request.default.get("/open3d/poster/sys/tagAndType/list", data);
}

/**
 * @description 
 */
function getPosterTemplateListApi(data) {
  return _request.default.get("/open3d/poster/sys/template/list", data);
}

/**
 * @description 
 */
function getStatusUpdateApi(data) {
  return _request.default.post("/open3d/poster/sys/status/update", data);
}

/**
 * @description 标签类型列表
 */
function getTagTypeApi(data) {
  return _request.default.get("/open3d/poster/sys/tagType/list", data);
}

/**
 * @description 保存和修改标签
 */
function getTagSaveUpdApi(data) {
  return _request.default.post("/open3d/poster/sys/tag/save", data);
}

/**
 * @description 删除标签
 */
function getTagDeleteApi(tag_id) {
  return _request.default.post("/open3d/poster/sys/tag/delete/".concat(tag_id), {});
}

/**
 * @description 设置模板标签
 */
function setTemplateSsaveApi(data) {
  return _request.default.post("/open3d/poster/sys/template/tag/save", data);
}

/**
 * @description 标签类型列表
 */
function gettemplateDetailApi(id) {
  return _request.default.get("/open3d/poster/sys/template/detail/".concat(id), {});
}

/**
 * @description 日历列表
 */
function getCalendarListApi(data) {
  return _request.default.get("/open3d/poster/app/calendar/list", data);
}

/**
 * @description 新增日历
 */
function setCalendarAddApi(data) {
  return _request.default.post("/open3d/poster/app/add", data);
}

/**
 * @description 新增日历
 */
function setCalendarEditApi(data) {
  return _request.default.post("/open3d/poster/app/edit", data);
}

/**
 * @description 删除标签
 */
function getCalendarDeleteApi(id) {
  return _request.default.post("/open3d/poster/app/torecords/delete/".concat(id), {});
}

/**
 * @description 
 */
function getCategoryListApi() {
  return _request.default.get("/open3d/draw/album/sys/category/list", {});
}

/**
 * @description 
 */
function setCategoryEditApi(data) {
  return _request.default.post("/open3d/draw/album/sys/category/save", data);
}